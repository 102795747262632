import React from "react";
import classNames from "classnames";
import { ReactComponent as LinkArrow } from "./assets/link-icon.svg";
import SessionHeader from "./SessionHeader";
import SessionSpeakers from "./SessionSpeakers";
import { SessionDataType } from "types/interfaces";
import "./index.scss";

type Props = {
    className?: string;
    data: SessionDataType;
    mainStage?: boolean;
    vertical?: boolean;
    withLink?: boolean;
    showSpeakers?: boolean;
    gradientSubtitle?: boolean;
    showSpeakerdescription?: boolean;
    isShowTime?: boolean;
    speakersTitle?: string;
    children?: React.ReactNode;
    togglePopup?: () => void;
    avatarSize?: "big" | "small";
    cardColor?: "gray" | "green" | "none";
    bgColor?: "white" | "green";
};

const SessionCard: React.FC<Props> = ({
    className,
    data,
    withLink,
    vertical,
    gradientSubtitle,
    showSpeakerdescription = false,
    isShowTime = false,
    children,
    showSpeakers = true,
    speakersTitle,
    togglePopup,
    avatarSize,
    cardColor = "green",
    bgColor = "white",
}) => {
    return (
        <div
            className={classNames("SessionCard", className, {
                "SessionCard--vertical": vertical,
                [`SessionCard--${cardColor}`]: cardColor,
                [`SessionCard--Bg-${bgColor}`]: bgColor,
            })}
        >
            {(withLink || data?.subtitle) && (
                <div className="SessionCard__Header">
                    <div className="SessionCard__Badges">
                        {data?.TimeString && isShowTime && (
                            <span className="SessionCard__SessionBadge SessionCard__SessionBadge--green">
                                {data.TimeString}
                            </span>
                        )}
                        {data?.subtitle && (
                            <span className="SessionCard__SessionBadge">
                                {data.subtitle}
                            </span>
                        )}
                    </div>
                    {withLink && !!data?.sessionDescription && (
                        <button
                            className={classNames("SessionCard__Btn", {
                                SessionCard__StatusFinished:
                                    data?.streamingStatus === "finished",
                            })}
                            type="button"
                            onClick={togglePopup}
                        >
                            <LinkArrow />
                        </button>
                    )}
                </div>
            )}
            <SessionHeader
                className={classNames({
                    SessionCard__StatusFinished:
                        data?.streamingStatus === "finished",
                })}
                title={data?.title || []}
                reference={data?.reference || ""}
                vertical={vertical}
                link={data?.link}
                gradientSubtitle={gradientSubtitle}
                togglePopup={data?.speakers ? togglePopup : undefined}
                hasLink={!!data?.speakers && !!data?.sessionDescription}
            >
                {children}
            </SessionHeader>
            {showSpeakers && (data?.speakers || data?.moderator) && (
                <div
                    className={classNames("SessionCard__Speakers", {
                        "SessionCard__Speakers--vertical": vertical,
                    })}
                >
                    {data?.moderator && (
                        <SessionSpeakers
                            className={classNames({
                                SessionCard__StatusFinished:
                                    data?.streamingStatus === "finished",
                            })}
                            speakers={data.moderator}
                            speakersTitle={data.moderator.length > 1 ? "Модераторы" : "Модератор"}
                            showSpeakerdescription={showSpeakerdescription}
                            avatarSize={avatarSize}
                        ></SessionSpeakers>
                    )}
                    {data?.speakers && (
                        <SessionSpeakers
                            className={classNames({
                                SessionCard__StatusFinished:
                                    data?.streamingStatus === "finished",
                            })}
                            speakers={data.speakers}
                            vertical={vertical}
                            speakersTitle={speakersTitle}
                            showSpeakerdescription={showSpeakerdescription}
                            avatarSize={avatarSize}
                        ></SessionSpeakers>
                    )}
                </div>
            )}
        </div>
    );
};
export default SessionCard;
