import React from "react";
import CompetitionHero from "./CompetitionHero";
import CompetitionFocus from "./CompetitionFocus";
import CompetitionStages from "./CompetitionStages";
import CompetitionJury from "./CompetitionJury";
import ShareBlock from "@/components/ShareBlock";
import CompetitionFAQ from "./CompetitionFAQ";
import Divider from "@/components/Divider";
import { Helmet } from 'react-helmet';
import './index.scss';

const Competition: React.FC = () => {
    return (
        <>
        <Helmet key="Competition">
            <title>Конкурс образовательных решений | СберУниверситет приглашает EdTech-компании</title>
            <meta
                name="description"
                content="Участвуйте во всероссийском конкурсе образовательных решений в рамках конференции «Больше чем обучение» от СберУниверситета. В конкурсе принимают участие как зрелые технологические компании, так и стартапы ►Возможность участвовать в выставке EdTech‑решений."
            />
        </Helmet>
        <main className="Competition">
            <div className="Competition__Box">
                <div>
                    <CompetitionHero />
                    <Divider />
                    <CompetitionFocus />
                </div>
                <CompetitionFAQ />
                <CompetitionJury />
                <CompetitionStages />
            </div>
            <ShareBlock />
        </main>
        </>
    )
}

export default Competition;