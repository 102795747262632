import React from 'react';
import { Navigate, Route, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Conference from '@/pages/Conference';
import Competition from "@/pages/Competition";
import RootLayout from '@/layouts/RootLayout';
import {sentryCreateBrowserRouter} from "@/utils/sentry";
import routes from '@/routes';
import NotFound from '@/pages/NotFound';
import { RequireNotAuth } from '@/components/RequireNotAuth';
import SignIn from '@/pages/SignIn';
import SignInBox from '@/pages/SignIn/SignInBox';
import ForgetPassword from '@/pages/SignIn/ForgetPassword';
import SignUp from '@/pages/SignUp';
import StudyOnline from '@/pages/SignUp/StudyOnline';
import FromSber from '@/pages/SignUp/FromSber';
import VipAccount from '@/pages/SignUp/VipAccount';
import StudyOnCampus from '@/pages/SignUp/StudyOnCampus';
import FromEducation from '@/pages/SignUp/FromEducation';
import ProtectedRoute from '@/components/ProtectedRoute';
import ProfileLayout from '@/layouts/ProfileLayout';
import {
    PersonalInformation,
    ChangeTariff,
    Certificates,
    ChangePassword,
    Refund,
} from '@/pages/Profile';
import Tariffs from '@/pages/Tariffs';
import { TariffCommon } from '@/pages/Profile/ChangeTariff/TariffCommon';
import { TariffEducation } from '@/pages/Profile/ChangeTariff/TariffEducation';
import { TariffSber } from '@/pages/Profile/ChangeTariff/TariffSber';
import ResetPassword from '@/pages/ResetPassword';
import { AuthProvider } from "react-auth-kit";
import { AxiosToken } from "@/providers/AxiosToken";
import ContextProviders from '@/providers/ContextProviders';
import { Helmet } from 'react-helmet';
import './index.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 2,
        },
    },
});

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />}>
            <Route index element={<Conference />} />

            <Route path={routes.contest} element={<Competition />} />

            <Route element={<RequireNotAuth redirectTo={routes.profile} />}>
                <Route path={routes.signin} element={<SignIn />}>
                    <Route index element={<SignInBox />} />
                    <Route path={routes.forgetPassword} element={<ForgetPassword />} />
                </Route>

                <Route path={routes.resetPassword} element={<ResetPassword />} />
            </Route>

            <Route path={routes.signup} element={<SignUp />}>
                <Route index element={<Navigate to={routes.onlineSignup} replace />} />

                {/* without payment*/}
                <Route element={<RequireNotAuth redirectTo={routes.profile} />}>
                    <Route path={routes.onlineSignup} element={<StudyOnline />} />
                    <Route path={routes.fromSberSignup} element={<FromSber />} />
                    <Route path={routes.vipSignup} element={<VipAccount />} />
                </Route>

                {/* with payment */}
                <Route path={routes.onCampusSignup} element={<StudyOnCampus />} />
                <Route path={routes.fromEducationSignup} element={<FromEducation />} />
            </Route>

            <Route element={<ProtectedRoute loginPath={routes.signin} />}>
                <Route element={<ProfileLayout />}>
                    <Route path={routes.profile} element={<PersonalInformation />} />
                    <Route path={routes.profileChangePassword} element={<ChangePassword />} />
                    <Route path={routes.profileTariffs} element={<Tariffs />} />
                    <Route path={routes.profileRefund} element={<Refund />} />
                    <Route path={routes.profileCertificates} element={<Certificates />} />
                </Route>
                <Route element={<ChangeTariff />}>
                    <Route path={routes.profileChangeTariffCommon} element={<TariffCommon />} />
                    <Route path={routes.profileChangeTariffEducation} element={<TariffEducation />} />
                    <Route path={routes.profileChangeTariffSber} element={<TariffSber />} />
                </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
        </Route>,
    ),
    {
        basename: process.env.PUBLIC_URL,
    },
);

const App: React.FC = () => {
    return (
        <div className="App">
            {/* DEFAULT HELMET  */}
            <Helmet key="default">
                <title>{process.env.REACT_APP_TITLE}</title>
                <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
            </Helmet>
            <AuthProvider authType={window.localStorage ? "localstorage" : "cookie"} authName={"_auth"}>
                <AxiosToken>
                    <QueryClientProvider client={queryClient}>
                        <ContextProviders>
                            <RouterProvider router={router} />
                        </ContextProviders>
                    </QueryClientProvider>
                </AxiosToken>
            </AuthProvider>
        </div>
    );
};

export default App;
