import React from 'react';
import Container from '@/components/Container';
import Headline from '@/components/Headline';
import StageCard from './StageCard';
import Stage01 from './assets/progress-1.png';
import Stage02 from './assets/progress-2.png';
import Stage03 from './assets/progress-3.png';
import Stage04 from './assets/progress-4.png';
import Typography from '@/components/Typography';
import { STAGES_HASH_ID } from '@/constants';
import './index.scss';

const stagesText = [
    {
        date: 'до 3 октября',
        progress: Stage01,
        stage: 'прием заявок',
    },
    {
        date: 'с 4 октября',
        progress: Stage02,
        stage: 'оценка заявок',
    },
    {
        date: 'до 14 октября',
        progress: Stage03,
        stage: 'подведение итогов',
    },
    {
        date: '25 октября',
        progress: Stage04,
        stage: 'финал и награждение',
    },
]

const CompetitionStages: React.FC = () => {
    const renderStages = stagesText.map((el, index) => <StageCard key={index} date={el.date} stage={el.stage} progress={el.progress} />)

    return (
        <section className="CompetitionStages" id={STAGES_HASH_ID}>
            <Container className='CompetitionStages__Container'>
                <div className='CompetitionStages__Header'>
                    <Headline level="h2" size="xlg" theme="light" isCentered>
                        Конкурс проходит
                        <br /> в четыре этапа
                    </Headline>
                    <Typography className='CompetitionFocus__Text' color="gray" size="md" weight="regular" isCentered>
                        Отправить заявку на участие можно до&nbsp;3&nbsp;октября. После подведения
                        <br /> итогов мы&nbsp;пригласим финалистов на конференцию&nbsp;«Больше чем обучение»,
                        <br /> где&nbsp;пройдет выставка решений и&nbsp;церемония награждения победителей
                    </Typography>
                </div>
                <div className='CompetitionStages__Wrapper'>
                    {renderStages}
                </div>
            </Container>

        </section>
    )
}

export default CompetitionStages;
